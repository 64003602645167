import React, { useState, useRef } from "react";
import Canvas from "./Canvas";
import TierSelect from "./TierSelect";
import TextArea from "./TextArea";
import Modal from "./Modal";

import logo from "./img/logo.jpg";
import topline from "./img/topline.jpg";
import bottomline from "./img/bottomline.jpg";
import { ShareIcon } from "@heroicons/react/solid";

function App() {
  const canvasRef = useRef(null);

  const [start, setStart] = useState(true);

  const [name, setName] = useState("");
  const [tier1, setTier1] = useState();
  const [tier2, setTier2] = useState();
  const [tier3, setTier3] = useState();
  const [tiertext1, setTierText1] = useState("");
  const [tiertext2, setTierText2] = useState("");
  const [tiertext22, setTierText22] = useState("");
  const [tiertext3, setTierText3] = useState("");

  const [url, setUrl] = useState("");
  const [open, setOpen] = useState(false);

  const sendToServer = () => {
    const canvas = canvasRef.current;
    let strDataURI = canvas.toDataURL("image/jpeg", 0.8);
    strDataURI = strDataURI.substr(22, strDataURI.length);

    fetch("/upload.php", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        img: strDataURI,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setUrl(data.url);
        setOpen(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (start) {
    return (
      <div
        className="flex flex-col h-full p-10 text-center item-center justify-items-center"
        style={{ background: "#f8f8f8" }}>
        <p>Mach deine eigne</p>
        <img src={logo} alt="logo" className="w-64 mx-auto" />
        <div>
          <button
            type="button"
            onClick={(e) => setStart(false)}
            className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-white bg-pink-600 border border-transparent rounded-md shadow-sm hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
            Los gehts
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full lg:flex" style={{ background: "#f8f8f8" }}>
      <div className="relative">
        <Canvas
          className="max-w-full"
          canvas={canvasRef}
          tier1={tier1}
          tier2={tier2}
          tier3={tier3}
          text={name}
          tiertext1={tiertext1}
          tiertext2={tiertext2}
          tiertext22={tiertext22}
          tiertext3={tiertext3}
        />
      </div>
      <div>
        <img src={topline} />
        <div className="flex flex-col p-4 space-y-4">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="name"
                id="name"
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Name eingeben..."
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div>
            <TierSelect
              tier={tier1}
              setTier={setTier1}
              label="Tier 1"
              setText={setTierText1}
            />
          </div>
          {tier1 && (
            <div>
              <TextArea tier={tier1} text={tiertext1} setText={setTierText1} />
            </div>
          )}
          <div>
            <TierSelect
              tier={tier2}
              setTier={setTier2}
              label="Tier 2"
              setText={setTierText2}
            />
          </div>
          {tier2 && (
            <div>
              <TextArea tier={tier2} text={tiertext2} setText={setTierText2} />
              <TextArea
                tier={tier2}
                text={tiertext22}
                setText={setTierText22}
              />
            </div>
          )}
          <div>
            <TierSelect
              tier={tier3}
              setTier={setTier3}
              label="Tier 3"
              setText={setTierText3}
            />
          </div>
          {tier3 && (
            <div>
              <TextArea tier={tier3} text={tiertext3} setText={setTierText3} />
            </div>
          )}
        </div>
        <img src={bottomline} />
        <div className="flex items-center">
          <button
            type="button"
            onClick={sendToServer}
            className="inline-flex items-center px-3 py-2 mx-auto mt-10 text-sm font-medium leading-4 text-white bg-pink-600 border border-transparent rounded-md shadow-sm hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
            <ShareIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            Auf Social Media teilen
          </button>
        </div>
      </div>
      <Modal open={open} setOpen={setOpen} url={url} />
    </div>
  );
}

export default App;
