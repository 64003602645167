import React, { Fragment, useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TextArea({ tier, text, setText }) {
  const handleChange = (event) => {
    setText(event.target.value);
  };
  return (
    <div className="flex items-start space-x-4">
      <div className="flex-shrink-0">
        <img
          className="inline-block w-10 h-10 rounded-full"
          src={`/img/${tier}.jpg`}
          alt=""
        />
      </div>
      <div className="flex-1 min-w-0">
        <form action="#" className="relative">
          <div className="overflow-hidden border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
            <label htmlFor="comment" className="sr-only">
              Sprechblasentext hinzufügen
            </label>
            <textarea
              rows={3}
              name="comment"
              id="comment"
              className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
              placeholder=" Sprechblasentext hinzufügen..."
              value={text}
              onChange={handleChange}
            />

            {/* Spacer element to match the height of the toolbar */}
            <div className="py-2" aria-hidden="true">
              {/* Matches height of button in toolbar (1px border + 36px content height) */}
              <div className="py-px">
                <div className="h-9" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
