import React, { useCallback, useEffect } from "react";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import tiere from "./tiere.json";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TierSelect = ({ tier, setTier, label, setText }) => {
  const cleanTierName = (tier) => {
    let name = tier;
    name = name.replace(/\u00e4/g, "ae");
    name = name.replace(/\u00fc/g, "ue");
    name = name.replace(/\u00f6/g, "oe");
    name = name.replace(/\u00df/g, "ss");
    name = name.replace(/\u00c4/g, "Ae");
    name = name.replace(/\u00dc/g, "Ue");
    name = name.replace(/\u00d6/g, "Oe");
    name = name.toLowerCase();

    return name;
  };

  return (
    <Listbox
      value={tier}
      onChange={(selectedTier) => {
        const tierObject = tiere.filter(
          (t) => cleanTierName(t.name) === selectedTier
        )[0];
        if (tierObject) {
          setText(tierObject.description);
        }

        setTier(selectedTier);
      }}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            {label}
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              {tier && (
                <span className="flex items-center">
                  <img
                    src={`/img/${cleanTierName(tier)}.jpg`}
                    alt=""
                    className="flex-shrink-0 w-6 h-6 rounded-full"
                  />
                  <span className="block ml-3 truncate">{tier}</span>
                </span>
              )}
              {!tier && (
                <span className="flex items-center">
                  <span className="block ml-3 truncate">
                    Bitte Tier auswählen
                  </span>
                </span>
              )}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {tiere.map((currentTier) => (
                  <Listbox.Option
                    key={currentTier.name}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={cleanTierName(currentTier.name)}>
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <img
                            src={`/img/${cleanTierName(currentTier.name)}.jpg`}
                            alt=""
                            className="flex-shrink-0 w-6 h-6 rounded-full"
                          />
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}>
                            {currentTier.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}>
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
export default TierSelect;
