import React, { useRef, useEffect } from "react";
import bg from "./img/background.jpg";

const Canvas = (props) => {
  const context = useRef(null);
  const oldText = useRef("");
  const oldText1 = useRef("");
  const oldText2 = useRef("");
  const oldText22 = useRef("");
  const oldText3 = useRef("");

  const drawImage = (img_url, x = 0, y = 0, width = 903, height = 647) => {
    const canvas = props.canvas.current;
    context.current = canvas.getContext("2d");

    const base_image = new Image();
    base_image.src = img_url;
    base_image.onload = function () {
      context.current.drawImage(base_image, x, y, width, height);
    };
  };

  const drawText = (text, old, x, y, size = "26", align = "left") => {
    const canvas = props.canvas.current;
    context.current = canvas.getContext("2d");
    context.current.font = size + "px sans-serif";
    context.current.textAlign = align;

    if (old) {
      const oldChunks = old.match(/.{1,18}(\s|$)/g);
      for (let i = 0; i < oldChunks.length; i++) {
        context.current.fillStyle = "#ffffff"; // or whatever color the background is.
        context.current.fillText(oldChunks[i], x, y + i * 15);
      }
    }

    if (text) {
      const chunks = text.match(/.{1,18}(\s|$)/g);
      for (let i = 0; i < chunks.length; i++) {
        context.current.fillStyle = "#222222"; // or whatever color the text should be.
        context.current.fillText(chunks[i], x, y + i * 15);
      }
    }
  };

  useEffect(() => {
    drawImage(bg);
  }, []);

  useEffect(() => {
    drawText(props.text, oldText.current, 265, 137, "26", "right");
    oldText.current = props.text;
  }, [props.text]);

  useEffect(() => {
    drawText(props.tiertext1, oldText1.current, 324, 219, "14");
    drawText(props.tiertext2, oldText2.current, 680, 230, "14");
    drawText(props.tiertext22, oldText22.current, 630, 380, "14");
    drawText(props.tiertext3, oldText3.current, 330, 430, "14");

    oldText1.current = props.tiertext1;
    oldText2.current = props.tiertext2;
    oldText22.current = props.tiertext22;
    oldText3.current = props.tiertext3;
  }, [props.tiertext1, props.tiertext2, props.tiertext22, props.tiertext3]);

  useEffect(() => {
    drawImage(`/img/${props.tier1}.jpg`, 96, 195, 187, 144);
    drawImage(`/img/${props.tier2}.jpg`, 451, 200, 191, 142);
    drawImage(`/img/${props.tier3}.jpg`, 104, 375, 190, 126);
  }, [props.tier1, props.tier2, props.tier3]);

  return <canvas ref={props.canvas} {...props} width={903} height={647} />;
};

export default Canvas;
